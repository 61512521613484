import React from "react";
import { ProcessType } from "../../types/process.types";

interface ProcessShipmentProps{
    process: ProcessType

}

const ProcessShipmentComponent: React.FC<ProcessShipmentProps> = ({process}) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Odbiór od klienta:</dt>
                <dd>{process.incomeShipment.shipmentMethod.name}</dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Dostawa do klienta:</dt>
                <dd>{process.shipment.shipmentMethod.name}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Opis:</dt>
                <dd>
                    {process.note && (
                        <em style={{whiteSpace: "pre-wrap"}}>{process.note}</em>
                    )}
                </dd>
            </dl>
        </>
    );
};

export default ProcessShipmentComponent;