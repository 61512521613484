import Swal, {SweetAlertOptions, SweetAlertResult} from "sweetalert2";

export const archiveFileSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy zarchiwizować plik?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, archiwizuj!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}