import React from "react";
import { ProcessType } from "../../types/process.types";
import {NumberFormat} from "../../../../components";

interface ProcessSummaryProps{
    process: ProcessType
}

const ProcessSummaryComponent: React.FC<ProcessSummaryProps> = ({process}) => {

    return (
        <>
            <dl className="dl-horizontal border-0">
                <dt>Status:</dt>
                <dd>{process.status.name}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Koszt:</dt>
                <dd><NumberFormat value={process.totalPriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Status płatności:</dt>
                <dd>{process.paymentStatus}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Data płatności:</dt>
                <dd>{process.paidAt}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Data rezygnacji:</dt>
                <dd>{process.resignationAt}</dd>
            </dl>
        </>
    );
};

export default ProcessSummaryComponent;