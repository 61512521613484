import React from "react";

interface FileSizeProps{
    size: number,
    decimal?: number
}

const FileSizeComponent:React.FC<FileSizeProps> = ({size, decimal = 2}) => {

    const thresh = 1024;
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10**decimal;

    if (Math.abs(size) < thresh) {
        return (<>{size} B</>);
    }

    do {
        size /= thresh;
        ++u;
    } while (Math.round(Math.abs(size) * r) / r >= thresh && u < units.length - 1);

    return (
        <>{size.toFixed(decimal) + ' ' + units[u]}</>
    );
}

export default FileSizeComponent;