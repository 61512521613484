import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getProcess} from "../../utils/axios";
import {ProcessType, ProcessItemType} from "../../types/process.types";
import {Maybe} from "@src/types/global.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleEuro, faFiles, faImages, faTruck, faListTimeline, faUser, faScrewdriverWrench, faClipboardCheck, faSpinner
} from "@fortawesome/pro-light-svg-icons";
import {
    ProcessCustomer,
    ProcessDropdownOptions,
    ProcessEstimate,
    ProcessEstimateList,
    ProcessFiles,
    ProcessImages,
    ProcessInfo,
    ProcessItem as ProcessItemComponent,
    ProcessShipment,
    ProcessSummary
} from "@process/components";
import {Col, Row, Tab, Tabs} from "react-bootstrap";

interface ProcessCardProps {
}

const ProcessCardView: React.FC<ProcessCardProps> = () => {
    const {id: processId} = useParams();
    const [process, setProcess] = useState<Maybe<ProcessType>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [activeProcessTab, setActiveProcessTab] = useState<string>('device');

    const fetchProcess = () => {
        setLoading(true);
        getProcess(processId).then((process: ProcessType) => {
            setProcess(process);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchProcess();
    }, []);


    return (
        <div className="view-process-card">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1 className="mb-0">
                    Karta serwisu
                    {process && (
                        <>: <span className="fw-light">{process.title}</span></>
                    )}
                </h1>
                <div>
                    {process && <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>}
                </div>
            </div>

            {process && (
                <>
                    <Row className="mb-3">
                        <Col>
                            <Tabs
                                defaultActiveKey="info"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="info"
                                     title={<><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/> Zgłoszenie</>}
                                >
                                    <ProcessInfo process={process} />
                                </Tab>

                                <Tab eventKey="customer"
                                     title={<><FontAwesomeIcon icon={faUser} fixedWidth={true}/> Klient</>}
                                >
                                    <ProcessCustomer process={process}/>
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col>
                            <Tabs defaultActiveKey="summary" className="mb-3">
                                <Tab eventKey="summary"
                                     title={<><FontAwesomeIcon icon={faClipboardCheck}
                                                               fixedWidth={true}/> Podsumowanie</>}
                                >
                                    <ProcessSummary process={process}/>
                                </Tab>
                                <Tab eventKey="esimation"
                                     title={<><FontAwesomeIcon icon={faClipboardCheck}
                                                               fixedWidth={true}/> Wycena</>}
                                >
                                    <ProcessEstimate process={process} />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                    <Row className="mb-3 mt-5">
                        <Col>
                            <Tabs
                                defaultActiveKey={activeProcessTab}
                                onSelect={(tab) => setActiveProcessTab(tab)}
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="device"
                                     title={<><FontAwesomeIcon icon={faScrewdriverWrench}
                                                               fixedWidth={true}/> Urządzanie</>}
                                >
                                    {process.items.map((processItem: ProcessItemType) => (
                                        <ProcessItemComponent
                                            processItem={processItem}
                                            process={process}
                                            refresh={fetchProcess}
                                            key={processItem.id}
                                        />
                                    ))}
                                </Tab>

                                <Tab eventKey="estimate"
                                     title={<><FontAwesomeIcon icon={faCircleEuro} fixedWidth={true}/> Kosztorysy</>}
                                >
                                    <ProcessEstimateList process={process} />
                                </Tab>
                                <Tab eventKey="files"
                                     title={<><FontAwesomeIcon icon={faFiles} fixedWidth={true}/> Dokumenty</>}
                                >
                                    <ProcessFiles process={process} />
                                </Tab>
                                <Tab eventKey="images"
                                     title={<><FontAwesomeIcon icon={faImages} fixedWidth={true}/> Zdjęcia</>}
                                >
                                    {activeProcessTab == 'images' && <ProcessImages process={process} />}
                                </Tab>
                                <Tab eventKey="shipment"
                                     title={<><FontAwesomeIcon icon={faTruck} fixedWidth={true}/> Dostawa</>}
                                >
                                    <ProcessShipment process={process} />
                                </Tab>
                                <Tab eventKey="flow"
                                     title={<><FontAwesomeIcon icon={faListTimeline} fixedWidth={true}/> Historia
                                         zmian</>}
                                >
                                    Historia zmian
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default ProcessCardView;