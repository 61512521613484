import React from "react";
import {EstimateItemType, EstimateType} from "@estimate/types/estimate.types";
import Table from "react-bootstrap/Table";
import {EstimateItemListLineComponent as EstimateItemListLine} from "@estimate/components";

interface EstimateItemListProps{
    estimate: EstimateType,
    fetchEstimate: Function
}

const EstimateItemListComponent: React.FC<EstimateItemListProps> = ({estimate, fetchEstimate}) => {

    if (estimate.items.length === 0){
        return (
            <div className="alert alert-info">Nie dodano jeszcze pozycji kosztorysu</div>
        )
    }

    return (
        <Table hover bordered size="sm">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Symbol</th>
                    <th>Produkt</th>
                    <th className="text-end">Ilość</th>
                    <th className="text-end">Jed.</th>
                    <th className="text-end">Cena jed. brutto</th>
                    <th className="text-end">Wartość brutto</th>
                    <th style={{width: 0}}></th>
                </tr>
            </thead>
            <tbody>
            {estimate.items.map((estimateItem: EstimateItemType, index: number)=> (
                <EstimateItemListLine
                    key={estimateItem.id}
                    index={index}
                    estimate={estimate}
                    estimateItem={estimateItem}
                    fetchEstimate={fetchEstimate}
                />
            ))}
            </tbody>
        </Table>
    )
}

export default EstimateItemListComponent;