import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {toast} from "react-toastify";
import {EstimateItemType, EstimateType} from "@estimate/types/estimate.types";
import {EstimateItemFormErrorType, EstimateItemFormType} from "@estimate/types/estimateItem.types";
import {Maybe} from "@src/types/global.types";
import {ProductSelectComponent} from "@product/components";
import {ProductType} from "@product/types/product.types";
import {FormDictionarySelectComponent} from "@dictionary/components";
import {updateEstimateItem} from "@estimate/utils/axios";

interface EstimateItemFormProps {
    estimate: EstimateType,
    estimateItem: EstimateItemType,
    afterSuccessSave?: Maybe<Function>,
}

const EstimateItemFormComponent: React.FC<EstimateItemFormProps> = ({estimate, estimateItem, afterSuccessSave}) => {

    const [isSending, setSending] = useState<boolean>(false);
    const [product, setProduct] = useState<Maybe<ProductType>>(estimateItem.product);
    const [formErrors, setFormErrors] = useState<EstimateItemFormErrorType>({});
    const [estimateItemForm, setEstimateItemForm] = useState<EstimateItemFormType>({
        product: estimateItem.product.id,
        quantity: estimateItem.quantity.toString(),
        unit: estimateItem.unit.id,
        unitPriceGross: estimateItem.unitPriceGross,
        note: estimateItem.note ?? ''
    });

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setEstimateItemForm(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onProductChangeHandler = (product: ProductType) => {
        setProduct(product)
        setEstimateItemForm(prevState => {
            return {...prevState, product: product.id, unitPriceGross: product.priceGross}
        });
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);
        updateEstimateItem(estimate.id, estimateItem.id, estimateItemForm)
            .then(() => {
                toast.success("Zapisano");
                afterSuccessSave && afterSuccessSave();
            })
            .finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>

            <Form.Group className="mb-3">
                <Form.Label className="required">Produkt:</Form.Label>
                <ProductSelectComponent
                    value={product}
                    onChange={onProductChangeHandler}
                    className={formErrors.product ? ' is-invalid' : ''}
                />
                {formErrors.product && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.product.map((error, key) => <div key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="required">Ilość:</Form.Label>
                <Form.Control
                    value={estimateItemForm.quantity ?? ''}
                    onChange={onChangeHandler}
                    name="quantity"
                    placeholder="Ilość"
                    className={formErrors.quantity ? ' is-invalid' : ''}
                />
                {formErrors.quantity && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.quantity.map((error, key) => <div key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="required">Jednostka miary:</Form.Label>
                <FormDictionarySelectComponent
                    model="Unit"
                    name="unit"
                    value={estimateItemForm.unit}
                    onChange={onChangeHandler}
                    required={true}
                    className={formErrors.unit ? ' is-invalid' : ''}
                />
                {formErrors.unit && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.unit.map((error, key) => <div key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="required">Cena jednostkowa brutto:</Form.Label>
                <InputGroup>
                    <Form.Control
                        value={estimateItemForm.unitPriceGross ?? ''}
                        onChange={onChangeHandler}
                        name="unitPriceGross"
                        placeholder="Cena jed. brutto"
                        className={(formErrors.unitPriceGross ? ' is-invalid' : '')}
                    />
                    <InputGroup.Text id="basic-addon1">zł</InputGroup.Text>
                    {formErrors.unitPriceGross && (
                        <Form.Control.Feedback type="invalid">
                            {formErrors.unitPriceGross.map((error, key) => <div key={key}>{error.message}</div>)}
                        </Form.Control.Feedback>
                    )}
                </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Uwagi</Form.Label>
                <Form.Control as="textarea" name="note" rows={3}
                              onChange={onChangeHandler}
                              value={estimateItemForm.note}
                />
                {formErrors.note && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.note.map((error, key) => <div key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default EstimateItemFormComponent;