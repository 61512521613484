import React, {useEffect, useState} from "react";
import {UserCard} from "@user/components";
import {useParams} from "react-router-dom";
import {Maybe} from "@src/graphql/$graphql";
import {Col, Row} from "react-bootstrap";
import {UserType} from "@user/types/user.types";
import {getUser} from "@user/utils/axios";

interface UserViewProps {}

const UserCardView: React.FC<UserViewProps> = () => {
    const { id } = useParams();
    const [user, setUser] = useState<Maybe<UserType>>();

    useEffect(() => {
        getUser(id).then((user: UserType) => setUser(user));
    }, []);

    return (
        <div className="view-user">
            <h1>Karta użytkownika</h1>
            <br/>

            <Row>
                <Col md={6} >
                    {user && <UserCard user={user} />}
                </Col>
            </Row>

        </div>
    )
}

export default UserCardView;