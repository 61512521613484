import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Table from 'react-bootstrap/Table';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus, faEllipsisVertical, faGear, faEye, faPen} from "@fortawesome/pro-regular-svg-icons";
import {getUserCollection} from "@user/utils/axios";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {UserType} from "@user/types/user.types";
import {Pagination, SortableColumn} from "@src/components";
import {Dropdown} from "react-bootstrap";
import {AuthRoles} from "@src/modules/auth/utils/AuthRoles";
import {auth, AuthState} from "@src/modules/auth/store/authSlice";
import {useSelector} from "react-redux";

interface UserListViewProps {}

const parseSearchParams = (search:string):any => {
    const locationParams = new URLSearchParams(search);
    let result = {
        page: Number(locationParams.get('page') ?? 1),
        order: [],
    };

    locationParams.forEach((value, key) => {
        if (key.startsWith('order[')) {
            const innerKey = key.slice(6, -1); // Extracting the inner key
            result.order.push({[innerKey]: value});
        }
    });
    return result;
}

const UserListView:React.FC<UserListViewProps> = () => {
    const locationParams = parseSearchParams(useLocation().search);
    const [userCollection, setUserCollection] = useState<UserType[]>([]);
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);

    const fetchUserCollection = () => {
        const filter: any = locationParams;
        getUserCollection(filter).then((paginationData: PaginationDataType<UserType>) => {
            setUserCollection(paginationData.items);
            setPagination(paginationData.pagination)
        })
    }

    useEffect(() => {
        fetchUserCollection();
    }, [useLocation().pathname, useLocation().search]);


    return (
        <div className="view-user-list">
            <div className="d-flex align-content-center align-items-center justify-content-between mb-4">
                <div>
                    <h1 className="m-0">Lista użytkowników</h1>
                </div>
                {hasAdminRole && (
                    <div>
                        <Link to="/user/create" className="btn btn-outline-primary">
                            <FontAwesomeIcon icon={faUserPlus} fixedWidth={true} /> dodaj użytkownika
                        </Link>
                    </div>
                )}
            </div>

            <Table bordered hover size="sm" >
                <thead>
                <tr>
                    <th scope="col">
                        <SortableColumn label="Imię" orderKey="name" params={locationParams}/>
                    </th>
                    <th scope="col">
                        <SortableColumn label="Nazwisko" orderKey="surname" params={locationParams}/>
                    </th>
                    <th scope="col">
                        <SortableColumn label="E-mail" orderKey="email" params={locationParams}/>
                    </th>
                    <th scope="col">Telefon</th>
                    <th scope="col">Oddział</th>
                    <td className="text-center" style={{width: 0}}>
                        <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                    </td>
                </tr>
                </thead>
                <tbody>
                    {userCollection.map((user: UserType) => (
                        <tr key={user.id}>
                            <td>
                                <Link to={'/user/'+user.id}>{user.name}</Link>
                            </td>
                            <td>
                                <Link to={'/user/'+user.id}>{user.surname}</Link>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.department?.name}</td>
                            <td className="text-center">
                                <Dropdown>
                                    <Dropdown.Toggle variant={"light"} size={"sm"}>
                                        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to={`/user/${user.id}`}>
                                            <FontAwesomeIcon icon={faEye} fixedWidth/> Karta
                                        </Dropdown.Item>
                                        {hasAdminRole && (
                                            <Dropdown.Item as={Link} to={`/user/edit/${user.id}`}>
                                                <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {pagination && <Pagination pagination={pagination} />}
        </div>
    );
}

export default UserListView;