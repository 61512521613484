import React, {ChangeEventHandler} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ProcessType} from "../../types/process.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUpload, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import {uploadProcessFiles} from "../../utils/axios";
import {toast} from "react-toastify";
import {Maybe} from "../../../../types/global.types";
import {FormDictionarySelect} from "../../../dictionary/components";

interface ProcessFilesProps {
    process: ProcessType,
    refresh: Function,
}

const ProcessFilesUploadComponent: React.FC<ProcessFilesProps> = ({process, refresh}) => {

    const [selectedFiles, setSelectedFiles] = React.useState<Maybe<FileList>>();
    const [selectedCategory, setSelectedCategory] = React.useState<string>('');
    const [isUploadingFiles, setUploadingFiles] = React.useState<boolean>(false);

    const onCategoryChangeHandler: ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCategory(e.target.value);
    }

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!selectedFiles || isUploadingFiles) {
            return;
        }

        const eventCurrentTarget = event.currentTarget;
        const formData: FormData = new FormData(eventCurrentTarget);
        if (selectedCategory) {
            formData.append('category', selectedCategory);
        }

        setUploadingFiles(true);
        uploadProcessFiles(process.id, formData)
            .then(() => {
                toast.success("Zapisano");
                eventCurrentTarget.reset();
                refresh();
            })
            .finally(() => {
                setUploadingFiles(false);
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(e.target.files);
    }

    return (
        <div>
            <Form className="mb-3" onSubmit={onSubmitHandler} method="post" encType="multipart/form-data">
                <Row>
                    <Col xs="auto">
                        <Form.Control placeholder="Wybierz pliki" name="files[]" type={"file"} multiple={true}
                                      onChange={onFileChangeHandler}
                        />
                    </Col>

                    {selectedFiles && (
                        <Col xs="auto">
                            <FormDictionarySelect
                                name="category"
                                model="ProcessFileCategory"
                                onChange={onCategoryChangeHandler}
                                value={selectedCategory}
                                placeholder="- brak kategorii -"
                            />
                        </Col>
                    )}

                    <Col xs="auto">
                        <Button type="submit" variant={"outline-primary"}
                                disabled={!selectedFiles || isUploadingFiles}
                        >
                            {isUploadingFiles ? (
                                <><FontAwesomeIcon icon={faSpinner} spin fixedWidth/>&nbsp;Wysyłam</>
                            ) : (
                                <><FontAwesomeIcon icon={faCloudUpload} fixedWidth/>&nbsp;Wyślij</>
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ProcessFilesUploadComponent;