import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {OfferFragment, useOfferQuery} from "../../../../graphql/$graphql";
import {OfferDiscountFormComponent, OfferItem, OfferItemForm} from "../../components";
import {OfferItem as OfferItemType } from "../../../../graphql/$graphql";
import ReactModal from "react-modal";
import {downloadFile, getUuidFromId, toDateFormat, toDateTimeFormat} from "../../../../helpers/utils.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faFilePdf, faSpinner, faXmark, faEnvelope } from "@fortawesome/pro-light-svg-icons";
import {NumberFormat} from "../../../../components";
import {client as clientAxios} from "../../../../library/axios.library";
import {downloadOffer, sendOfferEmail} from "../../utils/axios/OfferService";
import {toast} from "react-toastify";
import {Button} from "react-bootstrap";
import {sendOfferEmailSwal} from "../../utils/swal/Swal";

const OfferCardView = () => {
    const { id } = useParams();
    const [isDownloading, setDownloading] = useState<boolean>(false);
    const [showOfferItemForm, setShowOfferItemForm] = useState<boolean>(false);
    const [offerItem, setOfferItem] = useState<OfferItemType|null>(null);
    const {loading, data, refetch} = useOfferQuery({
        'variables': {'id': '/api/offers/'+id}
    });

    const offer:OfferFragment = data?.offer ?? null;

    const showOfferItemFormHandler = (isOpen:boolean, offerItem:OfferItemType = null) => {
        setShowOfferItemForm(isOpen);
        setOfferItem(offerItem);
    }

    const downloadOfferFile = () => {
        setDownloading(true);

        downloadOffer(id).then(response => {
            downloadFile(response.data, 'oferta.pdf')
        }).catch(error => {
            console.log('Error', error.message);
        }).finally(() => {
            setDownloading(false);
        })
    };

    const sendOfferToClientHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        sendOfferEmailSwal().then((result) => {
            if (result.isConfirmed) {
                sendOfferEmail(getUuidFromId(offer.id)).then(() => {
                    toast.success("Oferta została wysłana");
                    refetch();
                });
            }
        })
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="mb-0">Oferta</h1>
                <div>
                    <Button variant="outline-primary" onClick={downloadOfferFile} disabled={isDownloading}>
                        {isDownloading
                            ? <><FontAwesomeIcon icon={faSpinner} fixedWidth={true} spin={true}/> pobieram...</>
                            : <><FontAwesomeIcon icon={faFilePdf} fixedWidth={true}/> Pobierz PDF</>
                        }
                    </Button>
                    &nbsp;
                    <Button variant="outline-secondary" onClick={sendOfferToClientHandler}>
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/> Wyślij do klienta
                    </Button>
                </div>

            </div>

            {loading && (<div><FontAwesomeIcon icon={faSpinner} fixedWidth={true} spin={true}/> Loading...</div>)}

            {offer && (
                <>
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                            Szczegóły oferty
                                            </div>
                                            <Link to={`/offers/edit/` + getUuidFromId(offer.id)}>
                                                <FontAwesomeIcon icon={faPencil}/> edytuj
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <dl className="dl-horizontal">
                                            <dt></dt>
                                            <dd><h5 className="m-0">{offer.title}</h5></dd>
                                        </dl>
                                        <dl className="dl-horizontal">
                                            <dt>Data dodania:</dt>
                                            <dd>{toDateTimeFormat(offer.created)}</dd>
                                        </dl>
                                        <dl className="dl-horizontal">
                                            <dt>Status:</dt>
                                            <dd>{offer.status.name}</dd>
                                        </dl>
                                        <dl className="dl-horizontal">
                                            <dt>Numer:</dt>
                                            <dd>{offer.number}</dd>
                                        </dl>
                                        <dl className="dl-horizontal border-0">
                                            <dt>Termin:</dt>
                                            <dd>{offer.termDate && toDateFormat(offer.termDate)}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <div>Klient</div>
                                            <Link to={`/clients/edit/` + getUuidFromId(offer.client.id)}>
                                                <FontAwesomeIcon icon={faPencil}/> edytuj
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <dl className="dl-horizontal">
                                            <dt></dt>
                                            <dd><h5 className="m-0">{offer.client.name}</h5></dd>
                                        </dl>
                                        <dl className="dl-horizontal">
                                            <dt>NIP:</dt>
                                            <dd>{offer.client.nip}</dd>
                                        </dl>
                                        <dl className="dl-horizontal">
                                            <dt>E-mail:</dt>
                                            <dd>{offer.client.email}</dd>
                                        </dl>
                                        <dl className="dl-horizontal">
                                            <dt>Telefon:</dt>
                                            <dd>{offer.client.phone}</dd>
                                        </dl>
                                        <dl className="dl-horizontal border-0">
                                            <dt>Adres:</dt>
                                            <dd>
                                                <div>{offer.client.street}</div>
                                                <div>{offer.client.postCode + ' ' + offer.client.city}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="d-flex">
                        <div className="flex-fill text-center">
                            <div>Wartość bazowa</div>
                            <div className="fs-4 text-danger">
                                <NumberFormat value={offer.priceRegular} />
                            </div>
                        </div>
                        <div className="flex-fill text-center">
                            <div>Wartość rabatu</div>
                            <div className="fs-4">
                                <NumberFormat value={offer.priceDiscount} />
                            </div>
                        </div>
                        <div className="flex-fill text-center">
                            <div>Wartość rabatu %</div>
                            <div className="fs-4">
                                <NumberFormat value={offer.percentDiscount} extension="%"/>
                            </div>
                        </div>
                        <div className="flex-fill text-center">
                            <div>Wartość oferty</div>
                            <div className="fs-4 text-success">
                                <NumberFormat value={offer.price} />
                            </div>
                        </div>
                    </div>

                    <hr/>
                    <br/>

                    <div>

                        { offer.items.length > 0 && (
                            <OfferDiscountFormComponent id={id} refetch={refetch} />
                        )}

                        {offer.items.length === 0 && (
                            <div className="alert alert-info">Nie dodano jeszcze produktów</div>
                        )}

                        {offer.items.length > 0 && (
                            <table className="table table-hover table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Produkt</th>
                                    <th className="text-end">Cena podstawowa</th>
                                    <th className="text-end">Rabat</th>
                                    <th className="text-end">Rabat%</th>
                                    <th className="text-end">Cena</th>
                                    <th className="text-end">Ilość</th>
                                    <th className="text-end">Wartość</th>
                                    <th style={{width: 0}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {offer.items.map((offerItem, index) => (
                                    <OfferItem
                                        key={offerItem.id}
                                        index={index}
                                        offerItem={offerItem}
                                        refetch={refetch}
                                        showOfferItemFormHandler={showOfferItemFormHandler}
                                    />
                                ))}
                                </tbody>
                            </table>
                        )}

                        <button className="btn btn-outline-primary" onClick={() => showOfferItemFormHandler(true)}>dodaj
                            produkt
                        </button>

                        <ReactModal
                            isOpen={showOfferItemForm}
                            onRequestClose={() => showOfferItemFormHandler(false)}
                            overlayClassName="overlay overlay-dark"
                            className="overlay-wrap"
                            ariaHideApp={false}
                        >

                            <div className="overlay-header">
                                <h3 className="overlay-header-title">Produkt oferty</h3>
                            </div>

                            <button className="overlay-close" onClick={() => showOfferItemFormHandler(false)}>
                                <FontAwesomeIcon icon={faXmark} fixedWidth={true} />
                            </button>
                            <div className="overlay-body">
                                <OfferItemForm
                                    offer={offer}
                                    offerItem={offerItem}
                                    refetch={refetch}
                                    showOfferItemFormHandler={showOfferItemFormHandler}
                                />
                            </div>
                        </ReactModal>
                    </div>
                </>
            )}
        </>
    );

}

export default OfferCardView;