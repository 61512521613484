import React, {useEffect} from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {Maybe} from "@src/types/global.types";
import {getEstimate} from "@estimate/utils/axios";
import {useParams} from "react-router-dom";
import {EstimateCardComponent} from "@estimate/components";

interface EstimateCardProps{

}

const EstimateCardView: React.FC<EstimateCardProps> = () => {
    const [estimate, setEstimate] = React.useState<Maybe<EstimateType>>();
    const {id: estimateId} = useParams();

    const fetchEstimate = (): void => {
        getEstimate(estimateId).then((response: EstimateType) => {
            setEstimate(response);
        })
    }

    useEffect(() => {
        fetchEstimate();
    }, []);

    return (
        <div className="view-estimate-card">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1 className="mb-0">
                    Kosztorys
                    {estimate && (
                        <>: <span className="fw-light">{estimate.name}</span></>
                    )}
                </h1>
                <div>
                    {/*{false && process && <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>}*/}
                </div>
            </div>

            {estimate && <EstimateCardComponent estimate={estimate} fetchEstimate={fetchEstimate} />}
        </div>
    )
}

export default EstimateCardView;