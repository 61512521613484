import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useClientsLazyQuery} from "../../../../graphql/$graphql";
import {getUuidFromId} from "../../../../helpers/utils.helper";
import {Form} from "react-bootstrap";
import {CommonErrorType} from "../../../../types/global.types";
import {getClient} from "../../utils/axios";
import {ClientType} from "../../types/client.types";

interface FormUserSelectProps {
    changeHandler: Function,
    value: string,
    name?: string,
    errors?: CommonErrorType[],
    isClearable?: boolean
}

let timer: any;

const FormClientSelectComponent: React.FC<FormUserSelectProps> = (
    {changeHandler, value, name = "client", errors = [], isClearable = false}
) => {
    const [selectedClient, setSelectedClient] = useState<any>();
    const [inputValue, setInputValue] = useState<string>('');
    const [clientsQuery, {refetch}] = useClientsLazyQuery();
    const [clientList, setClientList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchClients = () => {
        setIsLoading(true);
        refetch({'search': inputValue})
            .then(clientsQueryResult => {
                const clients = clientsQueryResult?.data?.clients?.collection ?? [];
                let selectOptions = [];
                clients.map(client => {
                    selectOptions.push({value: getUuidFromId(client.id), label: client.name});
                })
                setClientList(selectOptions);
                setIsLoading(false);
            });
    }

    const onChangeHandler = (client: any) => {
        setSelectedClient(client);
        changeHandler(client?.value ?? '');
    }

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fetchClients()
        }, 250);
    }, [inputValue]);

    useEffect(() => {
        if (value) {
            getClient(value).then((client: ClientType) => {
                const clientOption = {value: client.id, label: client.name};
                setClientList((prevState:any) => {
                    return prevState.push(clientOption)
                });
                setSelectedClient(clientOption);
            })
        }
    }, []);

    return (
        <>
            <Select
                isClearable={isClearable}
                options={clientList}
                name="client"
                value={selectedClient}
                className={"p-0 form-select " + (errors.length > 0 ? ' is-invalid' : '')}
                inputValue={inputValue}
                onChange={onChangeHandler}
                onInputChange={value => setInputValue(value)}
                placeholder="Wybierz klienta"
                isLoading={isLoading}
                loadingMessage={() => "Szukam..."}
                noOptionsMessage={() => "Nie znaleziono klienta"}
                styles={{
                    control: (baseStyles) => ({...baseStyles, border: '0'})
                }}
            />
            {errors.length > 0 && (
                <Form.Control.Feedback type="invalid">
                    {errors.map(({message}, key) => <div key={key}>{message}</div>)}
                </Form.Control.Feedback>
            )}
        </>
    );
}

export default FormClientSelectComponent;
