import {AxiosResponse} from "axios";
import {client as clientAxios} from "@src/library/axios.library";
import {ChangePasswordFormType, RecoverPasswordFormType} from "@auth/types/auth.types";

export const recoverPassword = async (data: RecoverPasswordFormType): Promise<AxiosResponse> => {
    return await clientAxios.post<RecoverPasswordFormType, AxiosResponse>(`/api/v1/recover-password`, data);
}

export const changePassword = async (token: string, data: ChangePasswordFormType): Promise<AxiosResponse> => {
    return await clientAxios.post<ChangePasswordFormType, AxiosResponse>(`/api/v1/change-password/${token}`, data);
}
