import React, {useEffect} from "react";
import {ProcessFileType} from "@process/types/process.types";
import {AxiosResponse} from "axios";
import {getFile} from "../../../file/utils/axios";
import {downloadFile} from "../../../../helpers/utils.helper";
import {Maybe} from "@src/types/global.types";

import styles from './ProductImages.module.scss';

interface ProcessFilesProps {
    processFile: ProcessFileType
}

const ProcessImageComponent: React.FC<ProcessFilesProps> = ({processFile}) => {

    const [imageBlob, setImageBlob] = React.useState<Maybe<Blob>>();

    const fetchImage = (): void => {
        getFile(processFile.file.uuid)
            .then((response: AxiosResponse<Blob>) => {
                setImageBlob(response.data);
        })
    }

    useEffect(() => {
        fetchImage()
    }, []);

    if (!imageBlob) {
        return;
    }

    const image = window.URL.createObjectURL(imageBlob);

    return (
        <div className={styles.processImage} onClick={() => downloadFile(imageBlob)}>
            <img src={image} />
        </div>
    );
};

export default ProcessImageComponent;