import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {DepartmentFilterType, DepartmentType} from "@src/modules/department/types/department.types";
import {Maybe} from "@src/types/global.types";


export const getDepartmentCollection = async (filter?: Maybe<DepartmentFilterType>): Promise<DepartmentType[]> => {
    const {data} = await clientAxios.post<DepartmentFilterType, AxiosResponse<DepartmentType[]>>('/api/v1/department', filter);
    return data;
}
