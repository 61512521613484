import React from "react";

import { Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {auth} from "../../modules/auth/store/authSlice";
import {Login} from "../Login";
import {NavBar} from "../../modules/layout/components/NavBar";

const AppLayout: React.FC = (props) => {
    // const authData = useSelector(auth)
    //
    // if (false === authData.isAuth) {
    //     return (
    //         <Login />
    //     );
    // }

    return (
        <div className="app-layout-wrapper">
            <div className="app-layout-navigation">
                <NavBar />
            </div>
            <div className="app-layout-sidebar">Sidebar</div>
            <div className="app-layout-header">Header</div>
            <div className="app-layout-content">
                <div className="container">
                    <Outlet/>
                </div>
            </div>
            <div className="app-layout-footer">Footer</div>
        </div>
    );
}

export default AppLayout;