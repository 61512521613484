import React from "react";
import {Dropdown} from "react-bootstrap";
import {ProcessFileType, ProcessType} from "@process/types/process.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faEllipsisVertical,
    faPen,
    faBoxArchive,
} from "@fortawesome/pro-regular-svg-icons";
import {AxiosResponse} from "axios";
import {downloadFile, toDateTimeFormat} from "../../../../helpers/utils.helper";
import {archiveFile, getFile} from "../../../file/utils/axios";
import {FileSize} from "../../../../components";
import {archiveFileSwal} from "../../utils/swal";
import {SweetAlertResult} from "sweetalert2";
import {faXmark} from "@fortawesome/pro-light-svg-icons";
import ReactModal from "react-modal";
import ProcessFileForm from "./ProcessFileForm.component";

interface ProcessFileProps {
    process: ProcessType,
    processFile: ProcessFileType,
    refresh: Function,
}

const ProcessFileComponent: React.FC<ProcessFileProps> = ({process, processFile, refresh}) => {

    const [showProcessFileForm, setShowProcessFileForm] = React.useState<boolean>(false);

    const downloadProcessFile = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();

        getFile(processFile.file.uuid)
            .then((response: AxiosResponse<Blob>) => {
                const fileName = response.headers["content-disposition"]?.split(";")[1]?.replace(/filename=/g, "")
                    ?? processFile.file.filename;

                downloadFile(response.data, fileName);
            })
    }

    const archiveFileHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        archiveFileSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                archiveFile(processFile.file.uuid).then(() => {
                    refresh();
                })
            }
        })
    }

    const showProcessFileFormHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setShowProcessFileForm(prevState => !prevState);
    }

    return (
        <tr key={processFile.id}>
            <td>
                <a href="#" onClick={downloadProcessFile}>
                    {processFile.file.name}
                </a>
            </td>
            <td className="text-center">{processFile.category?.name ?? ''}</td>
            <td>{processFile.file.createdBy.name} {processFile.file.createdBy.surname}</td>
            <td className="text-center">{toDateTimeFormat(processFile.file.updatedAt)}</td>
            <td className="text-center">{processFile.file.extension}</td>
            <td className="text-end"><FileSize size={processFile.file.size} /></td>
            <td className="text-end">
                <Dropdown>
                    <Dropdown.Toggle variant={"outline-primary"} size={"sm"}>
                        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={downloadProcessFile}>
                            <FontAwesomeIcon icon={faDownload} fixedWidth /> Pobierz
                        </Dropdown.Item>
                        <Dropdown.Item onClick={showProcessFileFormHandler}>
                            <FontAwesomeIcon icon={faPen} fixedWidth /> Edytuj
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            className="text-danger"
                            onClick={archiveFileHandler}
                        >
                            <FontAwesomeIcon icon={faBoxArchive} fixedWidth /> Archiwizuj
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {showProcessFileForm && (
                    <ReactModal
                        isOpen={showProcessFileForm}
                        onRequestClose={showProcessFileFormHandler}
                        overlayClassName="overlay overlay-dark"
                        className="overlay-wrap"
                        ariaHideApp={false}
                    >
                        <div className="overlay-header">
                            <h3 className="overlay-header-title">Edycja pliku</h3>
                        </div>

                        <button className="overlay-close" onClick={showProcessFileFormHandler}>
                            <FontAwesomeIcon icon={faXmark} fixedWidth={true} />
                        </button>
                        <div className="overlay-body">
                            <ProcessFileForm
                                process={process}
                                processFile={processFile}
                                refresh={refresh}
                                setShowProcessFileForm={setShowProcessFileForm}
                            />
                        </div>
                    </ReactModal>
                )}
            </td>
        </tr>
    );
};

export default ProcessFileComponent;