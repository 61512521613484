import React from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {Link} from "react-router-dom";

interface EstimateInfoProps{
    estimate: EstimateType
}

const EstimateInfoComponent: React.FC<EstimateInfoProps> = ({estimate}) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Nazwa kosztorysu:</dt>
                <dd>
                    <Link to={`/estimate/${estimate.id}`}>
                        {estimate.name}
                    </Link>
                </dd>
            </dl>

            <dl className="dl-horizontal">
                <dt>Dodano:</dt>
                <dd className="d-flex justify-content-between">
                    <div>{estimate.createdBy.name + " " + estimate.createdBy.surname}</div>
                    <div className="text-muted small">{estimate.createdAt}</div>
                </dd>
            </dl>

            <dl className="dl-horizontal border-0">
                <dt>Ostatnia zmiana:</dt>
                <dd className="d-flex justify-content-between">
                    <div>{estimate.updatedBy.name + " " + estimate.updatedBy.surname}</div>
                    <div className="text-muted small">{estimate.updatedAt}</div>
                </dd>
            </dl>
        </>
    )
}

export default EstimateInfoComponent;