import React from "react";
import {EstimateFormComponent} from "@estimate/components";
import {EstimateFormType, EstimateType} from "@estimate/types/estimate.types";
import {updateEstimate} from "@estimate/utils/axios";
import {AxiosResponse} from "axios";

interface EstimateEditProps {
    estimate: EstimateType,
    afterSuccessSave: Function,
}

const EstimateEditComponent: React.FC<EstimateEditProps> = ({estimate, afterSuccessSave}) => {

    const initEstimateForm: EstimateFormType = {
        name: estimate.name
    }

    const saveEstimate = async (data: EstimateFormType): Promise<AxiosResponse> => {
        return updateEstimate(estimate.id, data);
    }

    return (
        <>
            <EstimateFormComponent
                initEstimateForm={initEstimateForm}
                saveEstimate={saveEstimate}
                afterSuccessSave={afterSuccessSave}
            />
        </>
    );
}

export default EstimateEditComponent;