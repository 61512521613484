import React from "react";
import { ProcessType } from "../../types/process.types";
import {NumberFormat} from "../../../../components";

interface ProcessEstimateProps{
    process: ProcessType
}

export const ProcessEstimateComponent: React.FC<ProcessEstimateProps> = ({process}) => {
    return (
        <>
            <dl className="dl-horizontal border-0">
                <dt>Kosztorys:</dt>
                <dd>@TODO - to będzie nazwa</dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Data akceptacji:</dt>
                <dd>@TODO - data</dd>
            </dl>

            <dl className="dl-horizontal">
                <dt>Koszt usługi:</dt>
                <dd><NumberFormat value={process.servicePriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Koszt dostawy:</dt>
                <dd><NumberFormat value={process.shipmentPriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Koszt płatności:</dt>
                <dd><NumberFormat value={process.paymentPriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Koszt całkowity:</dt>
                <dd><NumberFormat value={process.totalPriceGross}/></dd>
            </dl>
        </>
    );
};

export default ProcessEstimateComponent;