import React, {useEffect, useState} from "react";
import {ProcessType} from "../../types/process.types";
import {EstimateCreateComponent} from "@estimate/components";
import {EstimateFilterType, EstimateType} from "@estimate/types/estimate.types";
import {getEstimateList} from "@estimate/utils/axios";
import {toast} from "react-toastify";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faRefresh, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {ProcessEstimationListItemComponent as Item} from "./ProcessEstimationListItem.component" ;
import {Button} from "react-bootstrap";
import {faScrewdriverWrench} from "@fortawesome/pro-light-svg-icons";
import ReactModal from "react-modal";
import {ProcessEstimateFormCommon} from "@process/components";

interface ProcessEstimateListProps{
    process: ProcessType
}

export const ProcessEstimateListComponent: React.FC<ProcessEstimateListProps> = ({process}) => {

    const [estimateList, setEstimateList] = React.useState<EstimateType[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(true);
    const [showEstimateCreateModal, setEstimateCreateModal] = useState<boolean>(false);

    const fetchList = () => {
        const estimateFilter: EstimateFilterType = {
            process: process.id
        };

        setLoading(true);
        getEstimateList(estimateFilter)
            .then((response: EstimateType[]) => setEstimateList(response))
            .finally(() => setLoading(false))
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    useEffect(() => {
        fetchList();
    }, []);

    const afterSuccessSave = () => {
        setEstimateCreateModal(false);
        fetchList();
    }

    return (
        <>
            <Button variant={"outline-primary"} className={"mb-3"}
                    onClick={e => setEstimateCreateModal(true)}
            >
                <FontAwesomeIcon icon={faPlusCircle} fixedWidth={true}/> Nowy kosztorys
            </Button>
            <Table hover bordered size="sm" >
                <thead>
                <tr>
                    <th className="text-center">Data</th>
                    <th>Tytuł</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Dodany przez</th>
                    <th className="text-end">Wartość</th>
                    <th className="text-center">Data akceptacji</th>
                    <th className="text-end" style={{width: '0'}}>
                        <FontAwesomeIcon icon={faRefresh} fixedWidth={true} onClick={fetchList} spin={isLoading} />
                    </th>
                </tr>
                </thead>
                <tbody>
                    {estimateList.map((estimate:EstimateType) => (
                        <Item key={estimate.id} estimate={estimate} process={process} fetchList={fetchList} />
                    ))}
                </tbody>
            </Table>

            {showEstimateCreateModal && (
                <ReactModal
                    isOpen={showEstimateCreateModal}
                    onRequestClose={() => setEstimateCreateModal(false)}
                    overlayClassName="overlay overlay-dark"
                    className="overlay-wrap"
                    ariaHideApp={false}
                >
                    <div className="overlay-header">
                        <h3 className="overlay-header-title"><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/> Nowy kosztorys</h3>
                    </div>

                    <Button className="overlay-close" onClick={e => setEstimateCreateModal(false)}>
                        <FontAwesomeIcon icon={faXmark} fixedWidth={true}/>
                    </Button>
                    <div className="overlay-body">
                        <ProcessEstimateFormCommon process={process}/>
                        <EstimateCreateComponent
                            process={process}
                            afterSuccessSave={afterSuccessSave}
                        />
                    </div>
                </ReactModal>
            )}
        </>
    )
};

export default ProcessEstimateListComponent;