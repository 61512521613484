import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {UserChangePasswordFormErrorType, UserChangePasswordFormType, UserType} from "@user/types/user.types";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {changePassword} from "@user/utils/axios";
import {toast} from "react-toastify";


interface UserChangePasswordFormProps{
    user: UserType,
    afterSuccessSave: Function
}

const UserChangePasswordFormComponent: React.FC<UserChangePasswordFormProps> = ({
    user, afterSuccessSave
}) => {

    const [changePasswordForm, setChangePasswordForm] = useState<UserChangePasswordFormType>({});
    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<UserChangePasswordFormErrorType>({});

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setChangePasswordForm(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);
        changePassword(user.id, changePasswordForm)
            .then(() => {
                toast.success("Hasło zostało zmienione.");
                afterSuccessSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }


    return (
        <Form onSubmit={onSubmitHandler}>

            <Form.Group className="mb-3">
                <Form.Label className="required">Hasło:</Form.Label>
                <Form.Control
                    type="password"
                    value={changePasswordForm.password ?? ''}
                    onChange={onChangeHandler}
                    name="password"
                    className={formErrors.password ? ' is-invalid' : ''}
                />
                {formErrors.password && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.password.map((error, key) => <div key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="required">Powtórz hasło:</Form.Label>
                <Form.Control
                    type="password"
                    value={changePasswordForm.passwordRepeat ?? ''}
                    onChange={onChangeHandler}
                    name="passwordRepeat"
                    className={formErrors.passwordRepeat ? ' is-invalid' : ''}
                />
                {formErrors.passwordRepeat && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.passwordRepeat.map((error, key) => <div key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    )
}

export default UserChangePasswordFormComponent;