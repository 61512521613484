import React, {FormEventHandler, useState} from "react";
import {
    ProcessFileErrorType,
    ProcessFileFormType,
    ProcessFileType,
    ProcessType
} from "../../types/process.types";
import {Button, Form} from "react-bootstrap";
import {CommonErrorType, Maybe} from "../../../../types/global.types";
import {FormDictionarySelect} from "../../../dictionary/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {updateProcessFile} from "../../utils/axios";
import {toast} from "react-toastify";

interface ProcessFileFormProps {
    process: ProcessType,
    processFile: ProcessFileType,
    refresh: Function,
    setShowProcessFileForm: Function,
}

const ProcessFileFormComponent: React.FC<ProcessFileFormProps> = ({process, processFile, refresh, setShowProcessFileForm}) => {

    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<ProcessFileErrorType>({});
    const [processFileForm, setProcessFileForm] = useState<ProcessFileFormType>({
        name: processFile.file.name ?? '',
        category: processFile.category?.id ?? ''
    });

    const removeFromFormErrors = (key: string) => {
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[key];
            return newState;
        })
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcessFileForm(prevState => ({...prevState, [name]: value}));
        removeFromFormErrors(name);
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSending(true);
        updateProcessFile(process.id, processFile.file.uuid, processFileForm)
            .then(() => {
                toast.success("Zapisano");
                setShowProcessFileForm();
                refresh();
            })
            .finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
                <Form.Label>Nazwa pliku:</Form.Label>
                <Form.Control type="text" name="name"
                      value={processFileForm.name}
                      className={formErrors.name ? ' is-invalid' : ''}
                      onChange={onChangeHandler}
                />
                {formErrors.name && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.name.map((error: CommonErrorType, key: React.Key) => <div
                            key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Kategoria:</Form.Label>
                <FormDictionarySelect
                    name="category"
                    model="ProcessFileCategory"
                    onChange={onChangeHandler}
                    value={processFileForm.category}
                    errors={formErrors.category}
                />
            </Form.Group>

            <hr className="mb-4 mt-4"/>

            <Button size="lg" type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default ProcessFileFormComponent;