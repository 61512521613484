import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Maybe, PaginationDataType} from "@src/types/global.types";
import {UserFilterType, UserType} from "@user/types/user.types";
import {getUserCollection} from "@user/utils/axios";

interface UserSelectProps {
    onChange: any,
    value?: Maybe<UserType>,
    name?: string,
    className?: Maybe<string>
}

let timer: any;

const UserSelectComponent: React.FC<UserSelectProps> = (
    {onChange, value, name, className}
) => {
    const [userCollection, setUserCollection] = useState<UserType[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const fetchUserCollection = () => {
        const filter: UserFilterType = {search: inputValue}
        setLoading(true);
        getUserCollection(filter)
            .then((response: PaginationDataType<UserType>) => {
                setUserCollection(response.items);
            })
            .finally(() => {
                setLoading(false);
            })
        ;
    }

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fetchUserCollection()
        }, 250);
    }, [inputValue]);

    return (
        <Select
            options={userCollection}
            name={name}
            value={value}
            className={'p-0 form-select ' + (className ? ` ${className}` : '')}
            getOptionLabel={(user: UserType) => `${user.name} ${user.surname}`}
            getOptionValue={(user: UserType) => user.id}
            onChange={onChange}
            isLoading={isLoading}
            inputValue={inputValue}
            onInputChange={value => setInputValue(value)}
            placeholder="Wybierz użytkownika"
            noOptionsMessage={() => "Nie znaleziono użytkownika"}
            styles={{
                control: (baseStyles) => ({...baseStyles, width: 'auto', border: '0', minHeight: '0'}),
                dropdownIndicator: (baseStyles) => ({...baseStyles, padding: '0 8px'}),
            }}
        />
    );
}

export default UserSelectComponent;
