import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {
    ChangePasswordFormErrorType, ChangePasswordFormType,
} from "@auth/types/auth.types";
import {changePassword} from "@auth/utils/axios";
import {Link, useParams} from "react-router-dom";

interface ChangePasswordProps {
}

const ChangePasswordComponent: React.FC<ChangePasswordProps> = () => {
    const {token} = useParams();
    const [isSending, setSending] = React.useState<boolean>(false);
    const [isChanged, setChanged] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<ChangePasswordFormType>({});
    const [formErrors, setFormErrors] = useState<ChangePasswordFormErrorType>({});

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setFormData(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSending(true);

        changePassword(token, formData)
            .then(() => {
                setChanged(true);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }

    if (isChanged) {
        return (
            <div className="change-password-component">
                <div className="mb-3">Hasło zostało zmienione.</div>
                <Link to="/">
                    <Button variant={"outline-primary"}>
                        Przejdź do logowania &raquo;
                    </Button>
                </Link>
            </div>
        );
    }

    return (
        <div className="change-password-component">

            {formErrors.token && (
                <Alert variant="danger">
                    {formErrors.token.map((error, key) => <div key={key}>{error.message}</div>)}
                </Alert>
            )}

            <Form onSubmit={onSubmitHandler}>

                <Form.Group className="mb-3">
                    <Form.Label className="required">Nowe hasło:</Form.Label>
                    <Form.Control
                        type="password"
                        value={formData.password ?? ''}
                        onChange={onChangeHandler}
                        name="password"
                        className={formErrors.password ? ' is-invalid' : ''}
                    />
                    {formErrors.password && (
                        <Form.Control.Feedback type="invalid">
                            {formErrors.password.map((error, key) => <div key={key}>{error.message}</div>)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="required">Powtórz hasło:</Form.Label>
                    <Form.Control
                        type="password"
                        value={formData.passwordRepeat ?? ''}
                        onChange={onChangeHandler}
                        name="passwordRepeat"
                        className={formErrors.passwordRepeat ? ' is-invalid' : ''}
                    />
                    {formErrors.passwordRepeat && (
                        <Form.Control.Feedback type="invalid">
                            {formErrors.passwordRepeat.map((error, key) => <div key={key}>{error.message}</div>)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>

                <br/>
                <Button type="submit" variant="outline-primary" disabled={isSending}>
                    {isSending ? 'Wysyłam...' : 'Zmień hasło'}
                </Button>
            </Form>
        </div>
    );
};

export default ChangePasswordComponent