import moment from "moment";
import {useLocation} from "react-router-dom";

export const getUuidFromId = (path: string): string => {
    const paths = path.split('/');
    return paths[paths.length - 1];
}

export const toDateTimeFormat = (date: string): string => {
    return moment(date).format('YYYY-MM-DD HH:mm');
}

export const toDateFormat = (date: string): string => {
    return moment(date).format('YYYY-MM-DD');
}

export const objectToUrl = (obj, parentKey = null) => {
    let queryString = '';
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            const newKey = parentKey ? `${parentKey}[${key}]` : key;
            if (typeof value === 'object') {
                queryString += objectToUrl(value, newKey);
            } else {
                queryString += `${newKey}=${encodeURIComponent(value)}&`;
            }
        }
    }
    return queryString;
}

export const parseFilterParamsFromUrl = (url) => {
    const searchParams = new URLSearchParams(url);
    const paramsObject: any = {};

    // Iteracja przez każdy parametr
    for (const [key, value] of Array.from(searchParams.entries())) {
        // Rozbijanie klucza na tablicę indeksów i nazwy
        const keys = key.split(/\[|\]\[|\]/).filter(Boolean);
        let currentObj = paramsObject;

        // Tworzenie zagnieżdżonej tablicy lub obiektu na podstawie kluczy
        for (let i = 0; i < keys.length - 1; i++) {
            const currentKey = keys[i];
            const nextKey = keys[i + 1];

            // Sprawdzanie, czy następny klucz jest liczbą (czy jest to indeks tablicy)
            const isArrayIndex = /^[0-9]+$/.test(nextKey);

            if (!currentObj[currentKey]) {
                // Tworzenie tablicy lub obiektu, zależnie od typu zagnieżdżenia
                currentObj[currentKey] = isArrayIndex ? [] : {};
            }

            // Przechodzenie do następnego poziomu
            currentObj = currentObj[currentKey];
        }

        // Dodawanie wartości do tablicy lub obiektu
        const lastKey = keys[keys.length - 1];
        if (Array.isArray(currentObj)) {
            currentObj.push(value);
        } else {
            currentObj[lastKey] = value;
        }
    }

    paramsObject.page = Number(paramsObject?.page ?? 1);

    return paramsObject;
}

export const downloadFile = (data: any, fileName?: string): void => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const fileExtension = fileName?.split('.').pop()?.toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const isImage: boolean = imageExtensions.includes(fileExtension);

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    if (fileName && !isImage) {
        link.setAttribute('download', fileName)
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
}