import React from "react";
import {EstimateType} from "@estimate/types/estimate.types";

interface EstimateSummaryProps{
    estimate: EstimateType
}

const EstimateSummaryComponent: React.FC<EstimateSummaryProps> = ({estimate}) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Status:</dt>
                <dd>{estimate.status.name}</dd>
            </dl>

            <dl className="dl-horizontal border-0">
                <dt>Wycena:</dt>
                <dd className="h5 m-0 row">
                    <div className="col">{estimate.priceGross} <small className="text-muted">brutto</small></div>
                    <div className="col text-end">{estimate.priceNet} <small className="text-muted">netto</small></div>
                </dd>
            </dl>


        </>
    )
}

export default EstimateSummaryComponent;