import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {getUuidFromId, parseFilterParamsFromUrl} from "../../../../helpers/utils.helper";
import {ProcessFilterType} from "../../types/process.types";
import {
    Maybe,
    ProcessesQuery,
    ProcessFragment,
    ProcessPaginationInfo,
    useProcessesLazyQuery
} from "../../../../graphql/$graphql";
import {ApolloQueryResult} from "@apollo/client";
import {PaginationQl as Pagination} from "../../../../components/PaginationQl";
import Table from "react-bootstrap/Table";
import {SortableColumn} from "../../../../components";
import {ProcessDropdownOptions, ProcessFilter} from "../../components";

interface ProcessListProps {}
const defaultProcessFilter: ProcessFilterType = {
    page: 1,
    search: ''
}

const ProcessListView: React.FC<ProcessListProps> = () => {
    const locationParams = useLocation().search;
    const [processFilter, setProcessFilter] = useState<ProcessFilterType>(parseFilterParamsFromUrl(locationParams));
    const [processQuery, {loading}] = useProcessesLazyQuery();
    const [processList, setProcessList] = useState<ProcessFragment[]>([]);
    const [paginationInfo, setPaginationInfo] = useState<Maybe<ProcessPaginationInfo>>();

    const fetchProcesses = () => {
        const filter: ProcessFilterType = {...defaultProcessFilter, ...processFilter}
        processQuery({variables: filter})
            .then((processQuery: ApolloQueryResult<ProcessesQuery>) => {
                setProcessList(processQuery.data.processes.collection);
                setPaginationInfo(processQuery.data.processes.paginationInfo);
            });
    }

    useEffect(() => {
        const newProcessFilter = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newProcessFilter) !== JSON.stringify(processFilter)) {
            setProcessFilter(newProcessFilter);
        }
    }, [locationParams])

    useEffect(() => {
        fetchProcesses();
    }, [processFilter]);

    return (
        <div className="view-process-list">
            <div className="d-flex align-content-center align-items-center justify-content-between mb-5">
                <div>
                    <h1 className="m-0">Lista procesów serwisowych</h1>
                </div>
                <div>
                    <Link to="/process/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faPlus} fixedWidth={true}/> dodaj
                    </Link>
                </div>
            </div>

            <ProcessFilter processFilter={processFilter} />
            {loading && (<>Loading...</>)}

            {processList.length > 0 && (
                <>
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th scope="col">
                                <SortableColumn label="Data" orderKey="date" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Numer" orderKey="number" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Tytuł" orderKey="title" params={processFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <SortableColumn label="Status" orderKey="status_position" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Przypisane do" orderKey="user_surname" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Klient" orderKey="customer_lastName" params={processFilter}/>
                            </th>
                            <th scope="col" className="text-center" style={{width: 0}}>
                                <a href="#"><FontAwesomeIcon icon={faGear} /></a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {processList.map((process: ProcessFragment) => (
                            <tr key={process.id}>
                                <td>{process.date}</td>
                                <td>
                                    <Link to={`/process/` + getUuidFromId(process.id)}>{process.number}</Link>
                                </td>
                                <td>
                                    <Link to={`/process/` + getUuidFromId(process.id)}>{process.title}</Link>
                                </td>
                                <td className="text-center">{process.status.name}</td>
                                <td>{`${process.user.name} ${process.user.surname}`}</td>
                                <td>
                                    {process.customer.companyName && <div>{process.customer.companyName}</div>}
                                    <div>{`${process.customer.firstName} ${process.customer.lastName}`}</div>
                                    {process.customer.email && <div>E-mail: {process.customer.email}</div>}
                                    {process.customer.phone && <div>Tel: {process.customer.phone}</div>}
                                </td>
                                <td>
                                    <ProcessDropdownOptions process={process} buttonSize="sm" buttonVariant="light" />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {paginationInfo && <Pagination page={processFilter.page ?? 1} {...paginationInfo} />}
                </>
            )}
        </div>
    )
};

export default ProcessListView;
