import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {login, checkLogin, auth} from "@src/modules/auth/store/authSlice";
import {AppDispatch} from "@src/store";
import {client} from "@src/library/axios.library";
import {Link, useLocation} from "react-router-dom";
import {Col, Form, Row} from "react-bootstrap";

export interface LoginForm {
    email: string,
    password: string,
}

const defaultLoginForm: LoginForm = {
    email: '',
    password: '',
};

const Login: React.FC = (props) => {
    const locationParams = new URLSearchParams(useLocation().search)
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<LoginForm>(defaultLoginForm);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false)
    const authData = useSelector(auth)

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setLoginError(false);
        setFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setIsSending(true);
        client.post('/api/login', formData)
            .then(response => {
                dispatch(login({'token': response.data.token}));
            })
            .catch(error => {
                setLoginError(true);
            }).finally(() => {
                setIsSending(false);
            })
        ;
    }

    useEffect(() => {
        let token = null;
        if (locationParams.has('token')) {
            token = locationParams.get('token');
            dispatch(checkLogin(token));
        } else {
            token = window.localStorage.getItem('auth.token')
            if (token) {
                dispatch(checkLogin(token));
            }
        };
    }, [])


    if (true === authData.isCheckLogin) {
        return (
            <div className="container">
                <div className="d-flex justify-content-center align-items-center" style={{height: '200px'}}>
                    <h2>Logowanie...</h2>
                </div>
            </div>
        );
    }

    return (
        <div className="container flex-grow">
            <Row className="row justify-content-center d-flex align-items-center" style={{height: '100vh'}}>
                <Col className="col-5 border col-auto pb-3">
                    <h1>Logowanie</h1>
                    <hr/>

                    <Form onSubmit={onSubmitHandler}>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">E-mail</label>
                            <input
                                type="email"
                                name="email"
                                required={true}
                                value={formData.email}
                                onChange={onChangeHandler}
                                className={"form-control " + (loginError ? ' is-invalid' : '')}
                            />
                            {loginError && (
                                <div className="invalid-feedback">Błędny login lub hasło</div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Hasło</label>
                            <input
                                type="password"
                                name="password"
                                required={true}
                                value={formData.password}
                                onChange={onChangeHandler}
                                className={"form-control " + (loginError ? ' is-invalid' : '')}
                            />
                        </div>
                        <br/>
                        <button type="submit" className="btn btn-outline-primary"
                            disabled={isSending}
                        >
                            {isSending ? 'Loguję...' : 'Zaloguj'}
                        </button>
                    </Form>
                    <hr/>

                    <Link to="/recover-password">
                        odzyskaj hasło &raquo;
                    </Link>

                </Col>
            </Row>
        </div>
    );
}

export default Login;