import React, {ChangeEventHandler, useEffect, useState} from "react";
import {
    DictionaryFragment,
    useDictionariesLazyQuery,
} from "@src/graphql/$graphql";
import {getUuidFromId} from "@src/helpers/utils.helper";
import {Form} from "react-bootstrap";
import {CommonErrorType, Maybe} from "@src/types/global.types";

interface FormDictionarySelectProps {
    model: string,
    onChange: ChangeEventHandler,
    name?: Maybe<string>,
    value?: Maybe<string>,
    errors?: CommonErrorType[]
    required?: boolean,
    placeholder?: Maybe<string>,
    className?: Maybe<string>
}

const FormDictionarySelectComponent: React.FC<FormDictionarySelectProps> = (
    {model, onChange, value, name, errors = [], required = false, placeholder = '', className}
) => {
    const [query, {refetch}] = useDictionariesLazyQuery();
    const [dictionaryList, setDictionaryList] = useState<DictionaryFragment[]>([]);

    useEffect(() => {
        refetch({'model': model}).then(result => {
            setDictionaryList(result?.data?.dictionaries ?? []);
        })
    }, [])

    return (
        <>
            <Form.Select
                name={name}
                value={value ?? ''}
                onChange={onChange}
                isInvalid={errors.length > 0 ? true : false}
                className={(className ? ` ${className}` : '')}
            >
                {!required && (
                    <option value="" className="text-muted">{placeholder}</option>
                )}

                {dictionaryList.map(dictionary => (
                    <option value={getUuidFromId(dictionary.id)} key={dictionary.id}>
                        {dictionary.name}
                    </option>
                ))}
            </Form.Select>
            {errors.length > 0 && (
                <Form.Control.Feedback type="invalid">
                    {errors.map(({message}, key) => <div key={key}>{message}</div>)}
                </Form.Control.Feedback>
            )}
        </>
    );
}

export default FormDictionarySelectComponent;
