import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {PaginationType} from "@src/types/global.types";

interface PaginationInfoProps {
    pagination: PaginationType;
}

const Pagination:React.FC<PaginationInfoProps> = ({pagination}) => {
    const initSearchParams = Object.fromEntries(new URLSearchParams(useLocation().search));

    const pageLinkHandler: any = (page:any) => {
        let urlParams:any = {...initSearchParams, page: page};
        let pageUrl = '';

        if (Object.values(urlParams).length > 0) {
            pageUrl += '?' + (new URLSearchParams(urlParams).toString())
        }

        return pageUrl;
    }

    return (
        <>
            {pagination.total_pages > 1 && (
                <ul className="pagination">
                    {pagination.current_page > 1 && (
                        <>
                            <li className={"page-item page-item-prev " + (pagination.current_page === 1 && 'disabled')}>
                                <Link to={pageLinkHandler(pagination.current_page - 1)} className="page-link">
                                    <span aria-hidden="true">&laquo;</span>
                                </Link>
                            </li>

                            <li className={"page-item " + (pagination.current_page === 1 && 'active')}>
                                <Link to={pageLinkHandler(1)} className="page-link">1</Link>
                            </li>
                        </>
                    )}

                    {pagination.current_page > 3 && (
                        <li className="page-item disabled"><a className="page-link">...</a></li>
                    )}

                    {pagination.current_page > 2 && (
                        <li className={"page-item "}>
                            <Link to={pageLinkHandler(pagination.current_page - 1)} className="page-link">{pagination.current_page - 1}</Link>
                        </li>
                    )}

                    <li className={"page-item active"}>
                        <Link to={pageLinkHandler(pagination.current_page)} className="page-link">{pagination.current_page}</Link>
                    </li>

                    {pagination.current_page + 1 < pagination.total_pages && (
                        <li className={"page-item "}>
                            <Link to={pageLinkHandler(pagination.current_page + 1)} className="page-link">{pagination.current_page + 1}</Link>
                        </li>
                    )}

                    {pagination.current_page + 2 < pagination.total_pages && (
                        <li className="page-item disabled"><a className="page-link">...</a></li>
                    )}

                    {pagination.current_page < pagination.total_pages && (
                        <>
                            <li className={"page-item "}>
                                <Link to={pageLinkHandler(pagination.total_pages)} className="page-link">{pagination.total_pages}</Link>
                            </li>

                            <li className={"page-item page-item-next"}>
                                <Link to={pageLinkHandler(pagination.current_page + 1)} className="page-link">
                                    <span aria-hidden="true">&raquo;</span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            )}

            <div>
                Elementów na liście&nbsp;
                {pagination.current_page < pagination.total_pages && (<>{pagination.current_page * pagination.per_page} z {pagination.total_items}</>)}
                {pagination.current_page >= pagination.total_pages && (<>{pagination.total_items} z {pagination.total_items}</>)}
            </div>
        </>
    )
}

export default Pagination;