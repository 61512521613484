import React, {useState} from "react";
import {Button, Form, Row, Col} from "react-bootstrap";
import {UserFormErrorType, UserFormType} from "../../types/user.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import Container from 'react-bootstrap/Container';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {saveUser} from "@user/utils/axios";
import {FormDepartmentSelect} from "@src/modules/department/components";

interface UserFormProps {
    initialUserForm: UserFormType,
    id?: string
}

const UserFormComponent: React.FC<UserFormProps> = ({initialUserForm, id}) => {
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<UserFormErrorType>({});
    const [formData, setFormData] = useState<UserFormType>(initialUserForm);
    const [isSending, setIsSending] = useState<boolean>(false)

    const onSubmitHandler = e => {
        e.preventDefault();
        setIsSending(true);

        saveUser(formData, id)
            .then(response => {
                toast.success("Użytkownik został zapisany");
                navigate("/user/" + response.data.id);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => {
                setIsSending(false);
            })
    }

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if (type === 'file') {
            value = e.target.files[0];
        } else if (type === 'checkbox') {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        setFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    return (
        <Container className="component-user-form">
            <Form onSubmit={onSubmitHandler}>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Imię</Form.Label>
                            <Form.Control
                                type="text" value={formData.name}
                                name="name"
                                onChange={onChangeHandler}
                                isInvalid={formErrors.name ? true : false}
                            />
                            {formErrors.name && (
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.name.map((error, key) => <div key={key}>{error.message}</div>)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Nazwisko</Form.Label>
                            <Form.Control
                                name="surname"
                                type="text" value={formData.surname}
                                isInvalid={formErrors.surname ? true : false}
                                onChange={onChangeHandler}
                            />
                            {formErrors.surname && (
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.surname.map((error, key) => <div key={key}>{error.message}</div>)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                name="email"
                                type="text" value={formData.email}
                                isInvalid={formErrors.email ? true : false}
                                onChange={onChangeHandler}
                            />
                            {formErrors.email && (
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.email.map((error, key) => <div key={key}>{error.message}</div>)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control
                                name="phone"
                                type="text" value={formData.phone}
                                isInvalid={formErrors.phone ? true : false}
                                onChange={onChangeHandler}
                            />
                            {formErrors.phone && (
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.phone.map((error, key) => <div key={key}>{error.message}</div>)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3">
                    <Form.Label>Stanowisko</Form.Label>
                    <Form.Control
                        name="jobPosition"
                        type="text" value={formData.jobPosition}
                        isInvalid={formErrors.jobPosition ? true : false}
                        onChange={onChangeHandler}
                    />
                    {formErrors.jobPosition && (
                        <Form.Control.Feedback type="invalid">
                            {formErrors.jobPosition.map((error, key) => <div key={key}>{error.message}</div>)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Oddział</Form.Label>
                    <FormDepartmentSelect
                        name="department"
                        changeHandler={onChangeHandler}
                        value={formData.department}
                        isInvalid={formErrors.department ? true : false}
                    />
                    {formErrors.department && (
                        <Form.Control.Feedback type="invalid">
                            {formErrors.department.map(({message}, key) => <div key={key}>{message}</div>)}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isSending}>
                    {isSending ? (
                        <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                    ) : 'Zapisz'}
                </Button>
            </Form>
        </Container>
    )
}

export default UserFormComponent;
