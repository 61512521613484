import {client as clientAxios} from "../../../library/axios.library";
import {AxiosResponse} from "axios";
import {
    ProcessType,
    ProcessFormType,
    ProcessInitRequest,
    ProcessItemPartType,
    ProcessFileType, ProcessFileFormType, ProcessFileFilterType
} from "../types/process.types";
import {Maybe} from "../../../types/global.types";

export const initProcess = async (requestBody?: ProcessInitRequest): Promise<ProcessType> => {
    const {data} = await clientAxios.post<ProcessInitRequest, AxiosResponse<ProcessType>>('/api/v1/process/init', requestBody);
    return data;
}

export const getProcess = async (id: string): Promise<ProcessType> => {
    const {data} = await clientAxios.get<never, AxiosResponse<ProcessType>>('/api/v1/process/' + id);
    return data;
}

export const createProcess = async (data: ProcessFormType) => {
    return await clientAxios.post<ProcessFormType, AxiosResponse>('/api/v1/process', data);
}

export const updateProcess = async (id: string, data: ProcessFormType) => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/process/' + id, data);
}

export const initProcessItemPart = async (processId: string, processItemId: string): Promise<AxiosResponse> => {
    return await clientAxios.post<never, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/init`);
}

export const updateProcessItemPart = async (processId: string, processItemId: string, part: ProcessItemPartType): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessItemPartType, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/${part.id}`, part);
}

export const createProcessFileEntry = async (processId: string) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/process/' + processId + '/file/entry');
}

export const updateProcessFileEntry = async (processId: string) => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/process/' + processId + '/file/entry');
}

export const downloadProcessFileEntry = async (processId: string) => {
    return await clientAxios.get<never, AxiosResponse>('/api/v1/process/' + processId + '/file/entry/download', {
        responseType: 'blob',
    });
}

export const fetchProcessFiles = async (processId: string, filter?: Maybe<ProcessFileFilterType>) => {
    return await clientAxios.post<ProcessFileFilterType, AxiosResponse<ProcessFileType[]>>('/api/v1/process/' + processId + '/file', filter);
}

export const uploadProcessFiles = async (processId: string, formData: FormData) => {
    return await clientAxios.post<FormData, AxiosResponse>('/api/v1/process/' + processId + '/file/upload', formData);
}

export const updateProcessFile = async (
    processId: string,
    fileId: string,
    data: ProcessFileFormType,
): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessFileFormType, AxiosResponse>('/api/v1/process/' + processId + '/file/' + fileId, data);
}

