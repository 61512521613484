import React, {ReactElement, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    ClientFragment, ClientPaginationInfo, ClientsQuery, Maybe, useClientsLazyQuery
} from "../../../../graphql/$graphql";
import {PaginationQl as Pagination} from "../../../../components/PaginationQl";
import {getUuidFromId, parseFilterParamsFromUrl} from "../../../../helpers/utils.helper";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/pro-regular-svg-icons";
import SortableColumnComponent from "../../../../components/SortableColumn/SortableColumn.component";
import {ClientFilterType} from "../../types/client.types";
import {ClientFilter, ClientDropdownOptions} from "../../components";
import Table from "react-bootstrap/Table";
import {ProcessFilterType} from "../../../process/types/process.types";
import {ApolloQueryResult} from "@apollo/client";

interface ClientListProps {
}

const defaultOfferFilter: ClientFilterType = {
    page: 1,
    search: ''
}

const ClientListView: React.FC<ClientListProps> = (): ReactElement => {
    const locationParams = useLocation().search;
    const [clientFilter, setClientFilter] = useState<ClientFilterType>(parseFilterParamsFromUrl(locationParams));
    const [clientsQuery, {loading}] = useClientsLazyQuery();
    const [clientList, setClientList] = useState<ClientFragment[]>([]);
    const [paginationInfo, setPaginationInfo] = useState<Maybe<ClientPaginationInfo>>();

    const fetchClients = () => {
        const filter: ProcessFilterType = {...defaultOfferFilter, ...clientFilter}
        clientsQuery({variables: filter})
            .then((clientsQuery: ApolloQueryResult<ClientsQuery>) => {
                setClientList(clientsQuery.data.clients.collection);
                setPaginationInfo(clientsQuery.data.clients.paginationInfo);
            });
    }

    useEffect(() => {
        const newClientFilter = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newClientFilter) !== JSON.stringify(clientFilter)) {
            setClientFilter(newClientFilter);
        }
    }, [locationParams])

    useEffect(() => {
        fetchClients()
    }, [clientFilter]);

    return (
        <>
            <h1>Lista klientów</h1>
            <ClientFilter clientFilter={clientFilter}/>
            {loading && (<>Loading...</>)}

            {clientList.length > 0 && (
                <>
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th scope="col">
                                <SortableColumnComponent label="Dodano" orderKey="created" params={clientFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumnComponent label="Nazwa" orderKey="name" params={clientFilter}/>
                            </th>
                            <th scope="col">Nip</th>
                            <th scope="col">
                                <SortableColumnComponent label="E-mail" orderKey="email" params={clientFilter}/>
                            </th>
                            <th scope="col">Telefon</th>
                            <th scope="col">
                                <SortableColumnComponent label="Adres" orderKey="city" params={clientFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <a href="#">
                                    <FontAwesomeIcon icon={faGear}/>
                                </a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {clientList.map((client: ClientFragment) => (
                            <tr key={client.id}>
                                <td>{moment(client.created).format('YYYY-MM-DD HH:mm')}</td>
                                <td>
                                    <Link to={'/clients/' + getUuidFromId(client.id)}>
                                        {client.name}
                                    </Link>
                                </td>
                                <td>{client.nip}</td>
                                <td>{client.email}</td>
                                <td>{client.phone}</td>
                                <td>
                                    <div>{client.street}</div>
                                    <div>{client.postCode}&nbsp;{client.city}</div>
                                </td>
                                <td className="text-center">
                                    <ClientDropdownOptions client={client}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {paginationInfo && <Pagination page={clientFilter.page ?? 1} {...paginationInfo} />}
                </>
            )}
        </>
    );
}

export default ClientListView;