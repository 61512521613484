import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {ProcessType, ProcessErrorType, ProcessFormType, ProcessItemFormType} from "@process/types/process.types";
import {getProcess, updateProcess} from "@process/utils/axios";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {ProcessDropdownOptions, ProcessForm} from "@process/components";
import {Maybe} from "@src/types/global.types";

interface ProcessEditProps {
}

const ProcessEditView: React.FC<ProcessEditProps> = () => {
    const [processForm, setProcessForm] = React.useState<Maybe<ProcessFormType>>();
    const [formErrors, setFormErrors] = useState<ProcessErrorType>({});
    const [process, setProcess] = useState<Maybe<ProcessType>>();
    const [isSending, setIsSending] = useState<boolean>(false);
    const navigate = useNavigate();
    const { id: processId } = useParams();

    useEffect(() => {
        getProcess(processId).then((process: ProcessType) => {
            let processItems: ProcessItemFormType[] = [];
            process.items.map(processItem => processItems.push({
                id: processItem.id,
                name: processItem.name,
                serialNumber: processItem.serialNumber,
                note: processItem.note,
            }));

            let processForm: ProcessFormType = {
                departmentAccount: process?.departmentAccount?.id ?? '',
                number: process.number ?? '',
                user: process?.user?.id ?? '',
                status: process?.status?.id ?? '',
                title: process.title ?? '',
                date: process.date ?? '',
                note: process.note ?? '',
                customer: {
                    client: process?.customer?.client?.id ?? '',
                    firstName: process?.customer?.firstName ?? '',
                    lastName: process?.customer?.lastName ?? '',
                    companyName: process?.customer?.companyName ?? '',
                    email: process?.customer?.email ?? '',
                    phone: process?.customer?.phone ?? '',
                    taxNumber: process?.customer?.taxNumber ?? '',
                    address: {
                        street: process?.customer?.address?.street ?? '',
                        postCode: process?.customer?.address?.postCode ?? '',
                        city: process?.customer?.address?.city ?? '',
                    }
                },
                shipment: {
                    shipmentMethod: process?.shipment?.shipmentMethod?.uuid ?? ''
                },
                incomeShipment: {
                    shipmentMethod: process?.incomeShipment?.shipmentMethod?.uuid ?? ''
                },
                items: processItems
            };
            setProcess(process);
            setProcessForm(processForm);
        });
    }, []);

    const onSubmitHandler = (processForm: ProcessFormType) => {
        setIsSending(true);
        updateProcess(processId, processForm)
            .then(response => {
                toast.success("Zapisano");
                navigate("/process/" + processId);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => {
                setIsSending(false);
            })
    }

    return (
        <div className="view-process-create">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="m-0">Edycja procesu serwisowego</h1>
                <div>
                    {process && <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>}
                </div>
            </div>

            <Row>
                <Col>
                    {process && processForm &&
                        <ProcessForm
                            process={process}
                            processForm={processForm}
                            onSubmit={onSubmitHandler}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            isSending={isSending}
                        />}
                </Col>
            </Row>

        </div>
    )
};

export default ProcessEditView;