import React, {useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faScrewdriverWrench} from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";
import {UserType} from "@user/types/user.types";
import {auth, AuthState} from "@src/modules/auth/store/authSlice";
import {useSelector} from "react-redux";
import {AuthRoles} from "@src/modules/auth/utils/AuthRoles";
import {Button} from "react-bootstrap";
import {faKey, faXmark} from "@fortawesome/pro-light-svg-icons";
import ReactModal from "react-modal";
import {UserChangePasswordFormComponent} from "@user/components";

interface UserCardProps {
    user: UserType
}

const UserCardComponent: React.FC<UserCardProps> = ({user}) => {
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);
    const [showChangePasswordModal, setChangePasswordModal] = useState<boolean>(false);

    return (
        <>
            <Table bordered>
                <tbody>
                <tr>
                    <td scope="col-1" className={"text-end"}></td>
                    <td scope="col-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="m-0">{user.name + " " + user.surname}</h4>
                            {hasAdminRole && (
                                <Link to={"/user/edit/" + user.id}>
                                    <FontAwesomeIcon icon={faPencil}/> edytuj
                                </Link>
                            )}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="text-end">Telefon:</td>
                    <td>{user.phone}</td>
                </tr>
                <tr>
                    <td className="text-end">E-mail:</td>
                    <td>{user.email}</td>
                </tr>
                <tr>
                    <td className="text-end">Odział:</td>
                    <td>{user?.department?.name ?? ''}</td>
                </tr>
                <tr>
                    <td className="text-end">Stanowisko:</td>
                    <td>{user.jobPosition}</td>
                </tr>
                <tr>
                    <td className="text-end">Odział:</td>
                    <td>
                        {user.department && <>{user.department.code} {user.department.name}</>}
                    </td>
                </tr>
                </tbody>
            </Table>

            {hasAdminRole && (
                <>
                    <Button variant={"outline-primary"}
                            onClick={e => setChangePasswordModal(true)}
                    >
                        <FontAwesomeIcon icon={faKey} fixedWidth={true}/> Zmień hasło
                    </Button>

                    {showChangePasswordModal && (
                        <ReactModal
                            isOpen={showChangePasswordModal}
                            onRequestClose={() => setChangePasswordModal(false)}
                            overlayClassName="overlay overlay-dark"
                            className="overlay-wrap"
                            ariaHideApp={false}
                        >
                            <div className="overlay-header">
                                <h3 className="overlay-header-title"><FontAwesomeIcon icon={faKey}
                                  fixedWidth={true}/> Zmiana hasła użytkownika
                                </h3>
                            </div>

                            <Button className="overlay-close" onClick={e => setChangePasswordModal(false)}>
                                <FontAwesomeIcon icon={faXmark} fixedWidth={true}/>
                            </Button>
                            <div className="overlay-body">
                                <UserChangePasswordFormComponent
                                    user={user}
                                    afterSuccessSave={() => setChangePasswordModal(false)}
                                />
                            </div>
                        </ReactModal>
                    )}
                </>
            )}
        </>
    )
}

export default UserCardComponent;