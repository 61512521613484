import React, {useState} from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faScrewdriverWrench, faBoxArchive} from "@fortawesome/pro-light-svg-icons";
import {NumberFormat} from "@src/components";
import {toDateTimeFormat} from "@src/helpers/utils.helper";
import {Link} from "react-router-dom";
import {Button, Dropdown} from "react-bootstrap";
import {faEllipsisVertical, faPen, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {ProcessEstimateFormCommon} from "@process/components";
import ReactModal from "react-modal";
import {ProcessType} from "@process/types/process.types";
import EstimateEditComponent from "../../../estimate/components/EstimateEdit/EstimateEdit.component";
import {archiveEstimateSwal} from "@estimate/utils/swal";
import {SweetAlertResult} from "sweetalert2";
import {archiveEstimate} from "@estimate/utils/axios";
import {toast} from "react-toastify";

interface ProcessEstimationListItemProps{
    estimate: EstimateType
    process: ProcessType
    fetchList: Function
}

export const ProcessEstimationListItemComponent: React.FC<ProcessEstimationListItemProps> = (
    {estimate, process, fetchList}
) => {

    const [showEstimateEditModal, setEstimateEditModal] = useState<boolean>(false);

    const afterSuccessSave = () => {
        setEstimateEditModal(false);
        fetchList();
    }

    const archiveEstimateHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        archiveEstimateSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                archiveEstimate(estimate.id).then(() => {
                    toast.success("Kosztorys został przeniesiony do archiwum.");
                    fetchList();
                })
            }
        })
    }

    return (
        <tr>
            <td className="text-center">{toDateTimeFormat(estimate.createdAt)}</td>
            <td>
                <Link to={`/estimate/${estimate.id}`} >
                    {estimate.name}
                </Link>
            </td>
            <td className="text-center">{estimate.status.name}</td>
            <td>{`${estimate.createdBy.name} ${estimate.createdBy.surname}`}</td>
            <td className="text-end">
                <NumberFormat value={estimate.priceGross} />
            </td>
            <td className="text-center">{estimate.acceptedAt}</td>
            <td className="text-end" style={{width: '0'}}>
                <Dropdown>
                    <Dropdown.Toggle variant={"light"} size={"sm"}>
                        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/estimate/${estimate.id}`}>
                            <FontAwesomeIcon icon={faEye} fixedWidth/> Karta
                        </Dropdown.Item>
                        <Dropdown.Item onClick={e => setEstimateEditModal(true)}>
                            <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as={Link} to="#" className="text-danger" onClick={archiveEstimateHandler}>
                            <FontAwesomeIcon icon={faBoxArchive} fixedWidth/> Archiwizuj
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {showEstimateEditModal && (
                    <ReactModal
                        isOpen={showEstimateEditModal}
                        onRequestClose={() => setEstimateEditModal(false)}
                        overlayClassName="overlay overlay-dark"
                        className="overlay-wrap"
                    >
                        <div className="overlay-header">
                            <h3 className="overlay-header-title"><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/> Edycja kosztorysu</h3>
                        </div>

                        <Button className="overlay-close" onClick={e => setEstimateEditModal(false)}>
                            <FontAwesomeIcon icon={faXmark} fixedWidth={true}/>
                        </Button>
                        <div className="overlay-body">
                            <ProcessEstimateFormCommon process={process}/>
                            <EstimateEditComponent
                                estimate={estimate}
                                afterSuccessSave={afterSuccessSave}
                            />
                        </div>
                    </ReactModal>
                )}
            </td>
        </tr>
    )
};

export default ProcessEstimationListItemComponent;