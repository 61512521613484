import React from "react";
import {UserForm} from "@user/components";
import {UserFormType} from "@user/types/user.types";
import {Col, Row} from "react-bootstrap";

interface UserCreateProps {}

const UserCreateView: React.FC<UserCreateProps> = () => {
    const initialUserForm:UserFormType = {
        email: '',
        name: '',
        surname: '',
        phone: '',
        jobPosition: '',
        department: null,
    }

    return (
        <div className="view-user-create">
            <h1>Nowy użytkownik</h1>
            <Row>
                <Col sm md={{span: 8, offset: 2}} >
                    <UserForm initialUserForm={initialUserForm}/>
                </Col>
            </Row>
        </div>
    );
}

export default UserCreateView;
