import React from "react";
import { ProcessType } from "../../types/process.types";
import {Link} from "react-router-dom";

interface ProcessCustomerProps{
    process: ProcessType
}

const ProcessCustomerComponent: React.FC<ProcessCustomerProps> = ({process}) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dd>
                    <Link
                        to={process?.customer?.client ? `/clients/${process.customer.client.id}` : '#'}>
                        <h5 className="m-0">
                            {process.customer.firstName} {process.customer.lastName}
                        </h5>
                    </Link>
                </dd>
            </dl>
            {process.customer.companyName && (
                <dl className="dl-horizontal">
                    <dt>Firma:</dt>
                    <dd>{process.customer.companyName}</dd>
                </dl>
            )}
            {process.customer.taxNumber && (
                <dl className="dl-horizontal">
                    <dt>NIP:</dt>
                    <dd>{process.customer.taxNumber}</dd>
                </dl>
            )}

            <dl className="dl-horizontal">
                <dt>E-mail:</dt>
                <dd>
                    <a href={"mailto:" + process.customer.email}>
                        {process.customer.email}
                    </a>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Telefon:</dt>
                <dd>{process.customer.phone}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Adres:</dt>
                <dd>
                    <div>{process.customer.address.street}</div>
                    <div>{process.customer.address.postCode} {process.customer.address.city}</div>
                </dd>
            </dl>
        </>
    );
};

export default ProcessCustomerComponent;