import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Maybe} from "@src/types/global.types";
import {getDepartmentCollection} from "@src/modules/department/utils/axios";
import {DepartmentType} from "@src/modules/department/types/department.types";

interface FormDepartmentSelectProps {
    changeHandler: any,
    value: string,
    name?: Maybe<string>,
    isInvalid?: Maybe<boolean>
}

const FormDepartmentSelectComponent: React.FC<FormDepartmentSelectProps> = (
    {changeHandler, value, name, isInvalid}:FormDepartmentSelectProps
) => {
    const [departmentList, setDepartmentList] = useState<Maybe<DepartmentType[]>>();

    const fetchCollection = () => {
        getDepartmentCollection()
            .then((response: DepartmentType[]) => {
                setDepartmentList(response)
            })
    }

    useEffect(() => {
        fetchCollection();
    }, [])

    return (
        <Form.Select
            value={value}
            name={name}
            onChange={changeHandler}
            isInvalid={isInvalid}
        >
            <option value="">- wybierz oddział -</option>
            {departmentList && departmentList.map(department => (
                <option value={department.id} key={department.id}>
                    {department.code ? department.code + ' - ' : ''}{department.name}
                </option>
            ))}
        </Form.Select>
    );
}

export default FormDepartmentSelectComponent;
