import React, {useEffect, useState} from "react";
import {Maybe} from "@src/types/global.types";
import Select from "react-select";
import {getProductCollection} from "@product/utils/axios";
import {ProductFilterType, ProductType} from "@product/types/product.types";

interface FormProductSelectProps {
    onChange: any,
    value?: Maybe<ProductType>,
    name?: string,
    className?: Maybe<string>
}

let timer: any;

const ProductSelectComponent: React.FC<FormProductSelectProps> = (
    {onChange, value, name, className}
) => {
    const [productList, setProductList] = useState<ProductType[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchProducts = () => {
        const filter: ProductFilterType = {search: inputValue}
        setIsLoading(true);
        getProductCollection(filter)
            .then((response) => {
                setProductList(response.items);
            })
            .finally(() => {
                setIsLoading(false);
            })
        ;
    }

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fetchProducts()
        }, 250);
    }, [inputValue]);

    return (
        <Select
            options={productList}
            name={name}
            value={value}
            className={'p-0 form-select ' + (className ? ` ${className}` : '')}

            getOptionLabel={(product: ProductType) => `[${product.sku}] ${product.name}`}
            getOptionValue={(product: ProductType) => product.id}

            onChange={onChange}
            isLoading={isLoading}
            inputValue={inputValue}
            onInputChange={value => setInputValue(value)}
            placeholder="Wybierz produkt"
            noOptionsMessage={() => "Nie znaleziono produktu"}
            styles={{
                control: (baseStyles) => ({...baseStyles, width: 'auto', border: '0', minHeight: '0'}),
                dropdownIndicator: (baseStyles) => ({...baseStyles, padding: '0 8px'}),
            }}
        />
    )
}

export default ProductSelectComponent;