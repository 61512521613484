import React from "react";
import {Col, Row} from "react-bootstrap";
import {ChangePasswordComponent} from "@auth/components";

interface ChangePasswordProps {
}

const ChangePasswordView: React.FC<ChangePasswordProps> = () => {
    return (
        <div className="container flex-grow view-change-password">
            <Row className="justify-content-center d-flex align-items-center" style={{height: '100vh'}}>
                <Col className="col-5 border col-auto pb-3">
                    <h1>Zmień hasło</h1>
                    <hr/>
                    <ChangePasswordComponent />
                </Col>
            </Row>
        </div>
    );
};

export default ChangePasswordView;