import React from 'react';
import {Link, useLocation} from "react-router-dom";

interface PaginationInfoProps {
    page: number;
    lastPage: number;
    itemsPerPage?: number;
    totalCount?: number;
}

const PaginationQl:React.FC<PaginationInfoProps> = ({page, lastPage, itemsPerPage, totalCount}) => {
    const initSearchParams = Object.fromEntries(new URLSearchParams(useLocation().search));

    const pageLinkHandler: any = (page:any) => {
        let urlParams:any = {...initSearchParams, page: page};
        let pageUrl = '';

        if (Object.values(urlParams).length > 0) {
            pageUrl += '?' + (new URLSearchParams(urlParams).toString())
        }

        return pageUrl;
    }

    return (
        <>
            {lastPage > 1 && (
                <ul className="pagination">
                    {page > 1 && (
                        <>
                            <li className={"page-item page-item-prev " + (page === 1 && 'disabled')}>
                                <Link to={pageLinkHandler(page - 1)} className="page-link">
                                    <span aria-hidden="true">&laquo;</span>
                                </Link>
                            </li>

                            <li className={"page-item " + (page === 1 && 'active')}>
                                <Link to={pageLinkHandler(1)} className="page-link">1</Link>
                            </li>
                        </>
                    )}

                    {page > 3 && (
                        <li className="page-item disabled"><a className="page-link">...</a></li>
                    )}

                    {page > 2 && (
                        <li className={"page-item "}>
                            <Link to={pageLinkHandler(page - 1)} className="page-link">{page - 1}</Link>
                        </li>
                    )}

                    <li className={"page-item active"}>
                        <Link to={pageLinkHandler(page)} className="page-link">{page}</Link>
                    </li>

                    {page + 1 < lastPage && (
                        <li className={"page-item "}>
                            <Link to={pageLinkHandler(page + 1)} className="page-link">{page + 1}</Link>
                        </li>
                    )}

                    {page + 2 < lastPage && (
                        <li className="page-item disabled"><a className="page-link">...</a></li>
                    )}

                    {page < lastPage && (
                        <>
                            <li className={"page-item "}>
                                <Link to={pageLinkHandler(lastPage)} className="page-link">{lastPage}</Link>
                            </li>

                            <li className={"page-item page-item-next"}>
                                <Link to={pageLinkHandler(page + 1)} className="page-link">
                                    <span aria-hidden="true">&raquo;</span>
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            )}

            <div>
                Elementów na liście&nbsp;
                {page < lastPage && (<>{page * itemsPerPage} z {totalCount}</>)}
                {page >= lastPage && (<>{totalCount} z {totalCount}</>)}
            </div>
        </>
    )
}

export default PaginationQl;