import React from "react";
import {EstimateItemType, EstimateType} from "@estimate/types/estimate.types";
import {NumberFormat} from "@src/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faEye, faPen, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {Button, Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {faTrash} from "@fortawesome/pro-light-svg-icons";
import {SweetAlertResult} from "sweetalert2";
import {deleteEstimateItemSwal} from "@estimate/utils/swal";
import {deleteEstimateItem} from "@estimate/utils/axios";
import {toast} from "react-toastify";
import ReactModal from "react-modal";
import {EstimateItemFormComponent} from "@estimate/components";

interface EstimateItemListProps {
    index: number
    estimate: EstimateType
    estimateItem: EstimateItemType
    fetchEstimate: Function
}

const EstimateItemListLineComponent: React.FC<EstimateItemListProps> = (
    {index, estimate, estimateItem, fetchEstimate}
) => {

    const [showEstimateItemForm, setShowEstimateItemForm] = React.useState<boolean>(false);

    const deleteEstimateItemHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        deleteEstimateItemSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                deleteEstimateItem(estimate.id, estimateItem.id).then(() => {
                    toast.success("Pozycja kosztorysu została usunięta.");
                    fetchEstimate();
                })
            }
        })
    }

    const showEstimateItemFormHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setShowEstimateItemForm(prevState => !prevState);
    }

    const afterSuccessSave = (): void => {
        setShowEstimateItemForm(false);
        fetchEstimate();
    }

    return (
        <tr>
            <th>{index + 1}</th>
            <th>{estimateItem.sku}</th>
            <th>{estimateItem.name}</th>
            <th className="text-end">{estimateItem.quantity}</th>
            <th className="text-end">{estimateItem.unit.name}</th>
            <th className="text-end"><NumberFormat value={estimateItem.unitPriceGross}/></th>
            <th className="text-end"><NumberFormat value={estimateItem.totalPriceGross}/></th>
            <th style={{width: 0}}>
                <Dropdown>
                    <Dropdown.Toggle variant={"light"} size={"sm"}>
                        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/*<Dropdown.Item as={Link} to={'/process/' + estimateItem.id}>*/}
                        {/*    <FontAwesomeIcon icon={faEye} fixedWidth/> Karta*/}
                        {/*</Dropdown.Item>*/}
                        <Dropdown.Item onClick={showEstimateItemFormHandler}>
                            <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as={Link} to="#" className="text-danger" onClick={deleteEstimateItemHandler}>
                            <FontAwesomeIcon icon={faTrash} fixedWidth/> Usuń
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {showEstimateItemForm && (
                    <ReactModal
                        isOpen={showEstimateItemForm}
                        onRequestClose={showEstimateItemFormHandler}
                        overlayClassName="overlay overlay-dark"
                        className="overlay-wrap"
                        ariaHideApp={false}
                    >
                        <div className="overlay-header">
                            <h3 className="overlay-header-title">Edycja pozycji kosztorysu</h3>
                        </div>

                        <Button className="overlay-close" onClick={showEstimateItemFormHandler}>
                            <FontAwesomeIcon icon={faXmark} fixedWidth={true}/>
                        </Button>
                        <div className="overlay-body">
                            <EstimateItemFormComponent
                                estimate={estimate}
                                estimateItem={estimateItem}
                                afterSuccessSave={afterSuccessSave}
                            />
                        </div>
                    </ReactModal>
                )}
            </th>
        </tr>
    )
}

export default EstimateItemListLineComponent;