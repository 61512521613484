import {client as clientAxios} from "../../../library/axios.library";
import {AxiosResponse} from "axios";

export const getFile = async (fileId: string): Promise<AxiosResponse> => {
    return await clientAxios.get<never, AxiosResponse>('/api/v1/file/' + fileId, {
        responseType: 'blob',
    });
}

export const archiveFile = async (fileId: string): Promise<AxiosResponse> => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/file/' + fileId  + '/archive');
}