import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Maybe} from "@src/graphql/$graphql";
import {UserForm} from "@user/components";
import {UserFormType, UserType} from "@user/types/user.types";
import {Col, Row} from "react-bootstrap";
import {getUser} from "@user/utils/axios";

interface UserEditProps {}

const UserEditView: React.FC<UserEditProps> = () => {
    const { id } = useParams();
    const [user, setUser] = useState<Maybe<UserType>>();
    let initialUserForm: UserFormType;

    const fetchUser = () => {
        getUser(id).then((user: UserType) => {
            setUser(user);
        });
    }

    if (user) {
        initialUserForm = {
            name: user.name ?? '',
            surname: user.surname ?? '',
            email: user.email ?? '',
            phone: user.phone ?? '',
            jobPosition: user.jobPosition ?? '',
            department: user.department?.id ?? null,
        };
    }

    useEffect(() => {
        fetchUser()
    }, []);

    return (
        <div className="view-user-edit">
            <h1>Edycja użytkownika</h1>
            {user && (
                <Row>
                    <Col sm md={{span: 8, offset: 2}} >
                        <UserForm initialUserForm={initialUserForm} id={user.id} />
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default UserEditView;
