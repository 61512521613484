import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {ProductSelectComponent} from "@product/components";
import {EstimateItemFormErrorType, EstimateItemFormType} from "@estimate/types/estimateItem.types";
import {ProductType} from "@product/types/product.types";
import {Maybe} from "@src/graphql/$graphql";
import {createEstimateItem} from "@estimate/utils/axios";
import {EstimateType} from "@estimate/types/estimate.types";
import {UserFormErrorType} from "@user/types/user.types";

interface EstimateItemFormInlineProps{
    estimate: EstimateType,
    fetchEstimate: Function
}

const estimateItemFormDefault: EstimateItemFormType = {
    quantity: '1'
}

const EstimateItemFormInlineComponent: React.FC<EstimateItemFormInlineProps> = ({estimate, fetchEstimate}) => {

    const [product, setProduct] = useState<Maybe<ProductType>>();
    const [formErrors, setFormErrors] = useState<EstimateItemFormErrorType>({});
    const [estimateItemForm, setEstimateItemForm] = useState<EstimateItemFormType>(estimateItemFormDefault);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setEstimateItemForm(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();

        createEstimateItem(estimate.id, estimateItemForm)
            .then(()=> {
                fetchEstimate();
                setProduct(null);
                setEstimateItemForm(estimateItemFormDefault);
                setFormErrors({})
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
    }

    useEffect(() => {
        if (product) {
            setEstimateItemForm(prevState => {
                return {...prevState, product: product.id, unitPriceGross: product.priceGross}
            });
        }
    }, [product]);

    return (
        <>
            <Form className="mb-3" onSubmit={onSubmitHandler} >
                <Row>
                    <Col xs={7}>
                        {/*<Form.Label className="required">Produkt:</Form.Label>*/}
                        <ProductSelectComponent
                            value={product}
                            onChange={setProduct}
                            className={formErrors.product ?  ' is-invalid' : ''}
                        />
                        {formErrors.product && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.product.map((error, key) => <div key={key}>{error.message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Col>

                    <Col xs={2}>
                        {/*<Form.Label className="required">Ilość:</Form.Label>*/}
                        <Form.Control
                            value={estimateItemForm.quantity}
                            onChange={onChangeHandler}
                            name="quantity"
                            placeholder="Ilość"
                            className={formErrors.quantity ?  ' is-invalid' : ''}
                        />
                        {formErrors.quantity && (
                            <Form.Control.Feedback type="invalid">
                                {formErrors.quantity.map((error, key) => <div key={key}>{error.message}</div>)}
                            </Form.Control.Feedback>
                        )}
                    </Col>

                    <Col  xs={2}>
                        {/*<Form.Label className="required">Cena jed. brutto</Form.Label>*/}
                        <InputGroup>
                            <Form.Control
                                value={estimateItemForm.unitPriceGross ?? ''}
                                onChange={onChangeHandler}
                                name="unitPriceGross"
                                placeholder="Cena jed. brutto"
                                className={"text-end "+ (formErrors.unitPriceGross ?  ' is-invalid' : '')}
                            />
                            <InputGroup.Text id="basic-addon1">zł</InputGroup.Text>
                            {formErrors.unitPriceGross && (
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.unitPriceGross.map((error, key) => <div key={key}>{error.message}</div>)}
                                </Form.Control.Feedback>
                            )}
                        </InputGroup>
                    </Col>

                    <Col xs={1}>
                        <Button type="submit" style={{width:'100%'}}>
                            Dodaj
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )

}

export default EstimateItemFormInlineComponent;